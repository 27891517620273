/* eslint no-console:0 */

// Core Dependencies
import 'jquery';
import 'jquery-ujs';
import 'bootstrap';

// eslint-disable-next-line local-rules/css_modules
import 'react-datepicker/dist/react-datepicker.css';

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'common' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'lib/font_awesome/config';
import 'lib/font_awesome/brands';
import 'lib/font_awesome/regular';
import 'lib/font_awesome/solid';

// eslint-disable-next-line local-rules/css_modules
import 'lib/full_calendar/variables.css';
import 'lib/react_on_rails';

// This should always go first in regard to importing styles
import 'common/layout';

// Now the rest of our components
import 'common/flash';
import 'common/modals/global';

import 'common/pill';

import 'common/tooltip';
import 'common/tooltip_with_link';
import 'lib/hide_deprecation_warnings';

// This line gives us access to images that are compiled by webpack in ERB files.
// You can access them by using the `image_pack_tag` helper methods.
// https://github.com/shakacode/shakapacker#view-helper-image_pack_tag
require.context('../images', true);
