/* eslint-disable no-console */

const actual = console.error;
console.error = (...args) => {
  const errorToSwallow =
    'Support for defaultProps will be removed from function components in a future major release';
  if (
    args[0] &&
    typeof args[0] === 'string' &&
    args[0].indexOf(errorToSwallow) !== -1
  )
    return;
  actual(...args);
};
