import React from 'react';
import { Tooltip } from '@user-interviews/ui-design-system';

import * as propTypes from 'lib/prop_types';

function TooltipWithLink({ iconClasses, placement, text, theme }) {
  return (
    <Tooltip
      iconClasses={iconClasses}
      placement={placement}
      text={<span dangerouslySetInnerHTML={{ __html: text }} />}
      theme={theme}
    />
  );
}

TooltipWithLink.propTypes = {
  iconClasses: propTypes.string,
  placement: propTypes.string,
  text: propTypes.string.isRequired,
  theme: propTypes.string,
};

export default TooltipWithLink;
